import _ from "./index.scss"

import React from 'react'

import Wrapper from "ui-kit/components/Wrapper"

const Footer = ({children}) => {

  return (
    <div className="footer">
      <Wrapper>
        <div className="footer__inner">
          {children}
        </div>
      </Wrapper>
    </div>
  )

}

export default Footer