import _ from "./index.scss"

import React from 'react'

const Image = ({src, fit, style, className}) => {

  return (
    <img 
      src={src} 
      className={`${className} image`}
      style={{
        ...style,
        "--fit": fit
      }}
    />
  )
}

Image.defaultProps = {
  fit: "contain"
}

export default Image