import React, { useState } from 'react'

import Section from 'components/Section'
import Effect from 'components/Effect'
import Timerzone from 'components/Timerzone'
import Timer from 'components/Timer'
import Zone from 'components/Zone'
import Title from 'components/Title'

import Wrapper from 'ui-kit/components/Wrapper'
import Text from 'ui-kit/components/Text'
import Flex from 'ui-kit/components/Flex'

const Component = () => {

  const [progress, setProgress] = useState(0)

  const renderText = (text) => {
    return (
      <Wrapper>
        <Title size="base">
          {text}
        </Title>
      </Wrapper>
    )
  }

  return (
    <>
      <Section scale style={{minHeight: "20vh", margin: "0vh 0 20vh 0"}}>
        {renderText(
          <>
            <Title type="secondary" size="xsmall" label>Você dá um ótimo motivo para eles</Title>
            Optarem por planos mais longos
          </>
        )}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("Fidelizando eles por mais tempo")}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0 0 0"}}>
        {renderText("E você ganha em dobro")}
      </Section>

      <Section style={{minHeight: "150vh"}}>
        <Timerzone 
          onChange={(index, {progress}) => {
            setProgress(progress)
          }} 
          offsets={[[0, .5, .5, 1]]}
        >

          <Timer initial offset={[0, .5]}> 
            <Flex alignItems="center" justifyContent="center" style={{width: "100%", height: "100%"}}>
              {renderText((
                <>
                  No Whey e na Mensalidade
                  <Title type="secondary" size="xsmall" label style={{opacity: 0}}><br/>Além de previsibilidade</Title>
                  <Title type="secondary" size="xsmall" style={{opacity: 0}}>Ótimo para a quarentena</Title>
                </>
              ))}
            </Flex>
          </Timer>
          
          <Timer offset={[0, .5]}> 
            <Flex alignItems="center" justifyContent="center" style={{width: "100%", height: "100%"}}>
              {renderText((
                <>
                  No Whey e na Mensalidade
                  
                  <Zone value={progress} offset={[.2, .3]}>
                    {({ value, started }) => (
                      <Effect name="opacity" value={started ? value : 0}>
                        <Title type="secondary" size="xsmall">
                          <br/> Além de previsibilidade
                        </Title>
                      </Effect>
                    )}
                  </Zone>

                  <Zone value={progress} offset={[.5, .6]}>
                    {({ value, started }) => (
                      <Effect name="opacity" value={started ? value : 0}>
                        <Title type="secondary" size="xsmall">
                          Ótimo para a quarentena
                        </Title>
                      </Effect>
                    )}
                  </Zone>
                </>
              ))}
            </Flex>
          </Timer>

          <Timer offset={[.5, 1]}> 
            <Flex alignItems="center" justifyContent="center" style={{width: "100%", height: "100%"}}>
              {renderText((
                <>
                  No Whey e na Mensalidade
                  <Title type="secondary" size="xsmall"><br/>Além de previsibilidade</Title>
                  <Title type="secondary" size="xsmall">Ótimo para a quarentena</Title>
                </>
              ))}
            </Flex>
          </Timer>
        </Timerzone>
      </Section>

      <Section style={{minHeight: "20vh", margin: "0 0 50vh"}}>
        {renderText(
          <>
            Mas seu cliente ganha <Text color={Text.constants.color.orange50}>10x mais!</Text>
          </>
        )}
      </Section>
    </>
  )

}

export default Component