import _ from "./index.scss"

import React from 'react'

const Fade = ({children, delay, fade}) => {

  return (
    React.Children.map(children, child => (
      React.cloneElement(child, {
        className: `${child.props.className} fade`,
        style: {
          ...child.props.style, 
          "--delay": delay, 
          "--fade": fade
        }
      })
  )))

}

Fade.defaultProps = {
  fade: 1
}

export default Fade