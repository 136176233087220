import _ from "./index.scss"

import React, {useContext} from 'react'

import Wrapper from "ui-kit/components/Wrapper"

import { HeaderContext } from '../../providers/Header'
import { ScrollContext } from '../../providers/Scroll'

import Logo from 'images/logo.svg'

const Header = () => {

  const header = useContext(HeaderContext)
  const scroll = useContext(ScrollContext)

  return (
    <div className={`header ${header.blocked && 'header--hidden'} ${scroll.y <= 0 && 'header--expanded'}`}>
      <div className="header__inner">
        <Wrapper>
          <div className="header__actions">
            <div className="header__action">
              <img src={Logo} className="header__logo" />
            </div>

            <div className="header__action header__action--last">
              <a 
                href="https://api.whatsapp.com/send?phone=5548984819796&text=Oi!%20Vi%20a%20m%C3%A1quina%20de%20voc%C3%AAs%20no%20site,%20e%20gostaria%20de%20saber%20o%20que%20%C3%A9%20preciso%20fazer%20para%20entrar%20na%20lista%20de%20espera😁"
                className="header__link"
              >
                Entrar na fila de espera
              </a>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  )

}

export default Header
