import React, {Component} from 'react'
import styled from 'styled-components'

import PropTypes from 'prop-types'

const StyledComponent = styled.div`
  display: flex;
  flex-grow: ${props => props.flexGrow};
  flex-shrink: ${props => props.flexShrink};
  flex-direction: ${props => props.flexDirection};
  flex-wrap: ${props => props.flexWrap};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
`

const Flex = ({children, ...props}) => {
  return (
    <StyledComponent {...props}>
      {children}
    </StyledComponent>
  )
}

Flex.propTypes = {
  flexShrink: PropTypes.number,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexGrow: PropTypes.string
}

export default Flex