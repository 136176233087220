import _ from "./index.scss"

import React, {useRef, useEffect, useState, useContext, useCallback} from 'react'

const TimedAnimation = ({active, value, frames, frame}) => {

  const freeze = frame != null

  const imagesRef = useRef([])
  const progressRef = useRef()
  const requestRef = useRef()

  const [index, setIndex] = useState(null)

  if (freeze) {
    return (
      <div className="timed-animation">
        <img src={frames[frame] ? frames[frame] : null} />
      </div>
    )
  }

  useEffect(() => {
    if (active) {
      imagesRef.current = frames 
      requestRef.current = window.requestAnimationFrame(scrollPlay)
    } else {
      window.cancelAnimationFrame(requestRef.current)
    }
  }, [active])

  useEffect(() => {
    progressRef.current = value
  }, [value])

  const scrollPlay = useCallback(() => {
    if (active) {
      var nextIndex = Math.ceil(progressRef.current * (imagesRef.current.length - 1))
      setIndex(nextIndex)

      requestRef.current = window.requestAnimationFrame(scrollPlay)
    }
  }, [active])

  // TODO Render all frames?
  return (
    <div className="timed-animation">
      <img src={frames[index] ? frames[index] : null} />
    </div>
  )

}

export default TimedAnimation