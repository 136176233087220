import React, {useEffect, useState, useRef, useCallback, createContext} from 'react'
import { throttle } from 'throttle-debounce'

const ScrollContext = createContext()

const ScrollProvider = (props) => {

  const prevYRef = useRef()

  const [y, setY] = useState(0)

  useEffect(() => {
    prevYRef.current = y
  })

  const onScroll = useCallback(throttle(10, () => {
    setY(window.pageYOffset)
  }), [])

  const listenScroll = () => {
    document.addEventListener('scroll', onScroll)
  }

  const unlistenScroll = () => {
    document.removeEventListener('scroll', onScroll)
  }

  useEffect(() => {
    listenScroll()

    return () => {
      unlistenScroll()
    }
  })

  return (
    <ScrollContext.Provider 
      value={{
        y,
        direction: prevYRef.current > 0 ? prevYRef.current > y ? -1 : 1 : 0,
        windowHeight: window.innerHeight
      }}
    >
      {props.children}
    </ScrollContext.Provider>
  )
}

export {
  ScrollProvider,
  ScrollContext
}