import React from 'react'

var Wrapper = ({children, fullScreen, ...other}) => {

  return (
    <div 
      {...other}
      className="uiKit-wrapper"
    >
      <div className="uiKit-wrapper__inner">
        {children}
      </div>
    </div>
  )
}

export default Wrapper