import React, {useContext, useState} from 'react'

import Section from 'components/Section'
import Timerzone from 'components/Timerzone'
import Timer from 'components/Timer'
import TimedAnimation from 'components/TimedAnimation'
import Flex from 'ui-kit/components/Flex'

import { CacheContext } from '../../../../providers/Cache'

const Component = () => {

  const cache = useContext(CacheContext)

  const [index, setIndex] = useState(null)
  const [progress, setProgress] = useState(0)

  const offsets = [
    [0, 0],
    [0, .8],
    [.8, 1]
  ]

  return (
    <Section style={{minHeight: '700vh'}}>
      <Timerzone 
        offsets={offsets}
        onChange={(index, {progress}) => {
          setIndex(index)
          setProgress(progress)
        }} 
      >
        <Flex alignItems="center" style={{height: "100vh"}}>
          <Timer initial offset={offsets[0]}>
            <TimedAnimation 
              frame={0} 
              frames={cache.images.frames}
            />
          </Timer>
          
          <Timer offset={offsets[1]}>
            <TimedAnimation value={progress} active={index == 0} frames={cache.images.frames} />
          </Timer>

          <Timer offset={offsets[2]}>
            <TimedAnimation frame={cache.images.frames.length - 1} frames={cache.images.frames} />
          </Timer>
        </Flex>
      </Timerzone>
    </Section>
  )

}

export default Component