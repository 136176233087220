import React, {useEffect, useState, useRef, useContext, createContext} from 'react'

const SectionContext = createContext()

const SectionProvider = ({children, ...props}) => {

  const ref = useRef()
  const [dimensions, setDimensions] = useState({})
  const [progress, setProgress] = useState(0)

  const rect = () => {
    return ref.current.getBoundingClientRect()
  }

  useEffect(() => {
    if (ref.current) {
      setDimensions({
        height: ref.current.clientHeight,
        y: ref.current.offsetTop
      })
    }
  }, [ref])

  return (
    <SectionContext.Provider 
      value={{
        ...dimensions,
        focus,
        progress,
        rect,
        onChange: setProgress,
        ...props
      }}
    >
      <div className="section-provider" ref={ref}>
        {children}
      </div>
    </SectionContext.Provider>
  )
}

export {
  SectionProvider,
  SectionContext
}