import React from 'react'
import PropTypes from 'prop-types'

const Zone = ({children, offset, value}) => {

  const scaleValue = () => {
    return (value - offset[0]) / (offset[1] - offset[0])
  }

  var started, ended = false
  var scaledValue = value

  // Only matters when starts
  if (value >= offset[0]) {
    started = true
    scaledValue = scaleValue()
  }

  // TODO Review why here and not before
  if (scaledValue < 0) scaledValue = 0
  if (scaledValue > 1) scaledValue = 1

  return children({
    value: scaledValue, 
    started
  })

}

Zone.propTypes = {
  children: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
}

Zone.defaultProps = {
  offset: [0, 1]
}

export default React.memo(
  Zone, 
  (prevProps, nextProps) => prevProps.value == nextProps.value
)
