import _ from "./index.scss"

import React, { useContext, useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { ScrollContext } from '../../providers/Scroll'
import { HeaderContext } from '../../providers/Header'
import { SectionProvider, SectionContext } from './provider'

const Section = ({children, ...props}) => {

  const {ref, inView} = useInView()

  return (
    <SectionProvider>
      <div ref={ref}>
        <SectionContent inView={inView} {...props}>
          {children}
        </SectionContent>
      </div>
    </SectionProvider>
  )

}

var SectionContent = ({children, threshold, isLast, inView, hideHeader, scale, onChangeFocus, onChangeVisibility, style, ...props}) => {
  
  const prevInViewRef = useRef(inView)
  const progressRef = useRef()
  const [opacity, setOpacity] = useState(0)

  const header = useContext(HeaderContext)
  const scroll = useContext(ScrollContext)
  const section = useContext(SectionContext)

  useEffect(() => {
    handleCalculations()

    if (changedVisibility()) prevInViewRef.current = inView 
  }, [scroll.y, inView])

  useEffect(() => onChangeVisibility(inView), [inView])

  const changedVisibility = () => {
    return inView != prevInViewRef.current
  }

  const wasVisible = () => prevInViewRef.current

  const handleFocus = () => {
    var rect = section.rect()

    if (
      Math.round(rect.top) <= 0 && 
      (
        Math.round(rect.bottom) >= scroll.windowHeight ||
        isLast
      )
    ) {
      onChangeFocus(true)
      if (hideHeader) header.setBlocked(true)
    } else {
      onChangeFocus(false)
      if (hideHeader) header.setBlocked(false)
    }
  }

  const handleCalculations = () => {
    if (inView) {
      handleFocus()
      handleOpacity()
      handleProgress()
    } else {
      // Force progress pin when scroll in case scroll skip
      if (wasVisible()) {
        if (scroll.direction == 1) {
          section.onChange(1)
        } else {
          section.onChange(0)
        }
      }
    }
  }
  
  const handleOpacity = () => {
    const rect = section.rect()
    const windowHeight = scroll.windowHeight

    const progressIn = 1 - (rect.y / windowHeight)
    const progressOut = (rect.y + section.height) / windowHeight

    progressRef.current = progressIn

    if (progressIn < 0) {
      setOpacity(0)
    } else if (progressIn >= 0 && progressIn <= threshold) {      
      setOpacity(progressIn / threshold)
    } else if (progressOut >= 0 && progressOut <= threshold) {
      if (isLast) {
        setOpacity(1)  
      } else {
        setOpacity(1 - (Math.abs((progressOut / threshold) - 1)))
      }
    } else {
      setOpacity(1)
    }
  }

  const handleProgress = () => {
    const elYIn = section.y
    const elYOut = section.y + section.height

    const progress = (scroll.y - elYIn) / (elYOut - elYIn)
    const progressRound = parseFloat(progress.toFixed(4))

    section.onChange(progressRound)
  }

  if (scale) {
    return (
      <div 
        {...props} 
        className="section"
        style={{
          ...style,
          "--opacity": progressRef.current < 0 || progressRef.current > 1 ? 0 : opacity < 1 ? 0  : 1,
          "--scale": progressRef.current < 0 || progressRef.current > 1 ? .98 : opacity < 1 ? .98 : 1
        }}
      >
        {children}
      </div>
    )
  } else {
    return (
      <div 
        {...props} 
        className="section"
        style={{
          ...style,
          "--opacity": opacity
        }}
      >
        {children}
      </div>
    )
  }

}

Section.defaultProps = {
  threshold: .4,
  onChangeFocus: () => {},
  onChangeVisibility: () => {}
}

export default Section