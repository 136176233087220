import React, { useState } from 'react'

import Section from 'components/Section'
import Video from 'components/Video'
import Title from 'components/Title'

import Wrapper from 'ui-kit/components/Wrapper'

import Video1 from 'images/videos/video5.mp4'

const Component = () => {

  const [visible, setVisibility] = useState(false)

  const fillParent = { width: "100%", height: "100%" }

  const renderText = (text) => {
    return (
      <Wrapper>
        <Title size="base">
          {text}
        </Title>
      </Wrapper>
    )
  }

  return (
    <>
      <Section scale style={{minHeight: "20vh", margin: "50vh 0"}}>
        {renderText("Seu cliente nunca mais vai precisar lavar coqueteleira")}
      </Section>

      <Section style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("O que nossos consumidores tem a dizer?")}
      </Section>

      <Section 
        style={{height: "125vh"}}
        hideHeader
        onChangeVisibility={setVisibility}
      >
        <div style={{width: "100%", height: "100vh", position: "sticky", top: 0}}>
          <div style={{position: "absolute", top: 0, ...fillParent}}>
            <Video src={Video1} unmuteControl start={visible} />
          </div>
        </div>
      </Section>
    </>
  )

}

export default Component