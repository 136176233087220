import _ from "./index.scss"

import React, {useState, useContext, useEffect, useRef} from 'react'

import { ScrollContext } from '../../providers/Scroll'

const Contact = ({}) => {

  const lastChangeScroll = useRef({
    y: null,
    direction: 0
  })
  
  const scroll = useContext(ScrollContext)

  const [visible, setVisibility] = useState(true)

  useEffect(() => {
    const {y, direction} = lastChangeScroll.current

    if (scroll.y == 0) {
      setVisibility(false)

      lastChangeScroll.current = {
        y: null,
        direction: 0
      }
    } else {
      if (direction == 1) {
        if (scroll.y - y > 150) {
          setVisibility(false)
        }
      }
      
      if (direction == -1) {
        if (y - scroll.y > 250) {
          setVisibility(true)
        }
      }

      if (scroll.direction != direction) {
        lastChangeScroll.current = {
          y: scroll.y,
          direction: scroll.direction
        }
      }
    }
  }, [scroll.y])

  return (
    <div className="contact">
      <a className={`contact__button ${!visible && 'contact__button--hidden'}`} href="https://api.whatsapp.com/send?phone=5511991173788&text=Oi!%20Vi%20a%20m%C3%A1quina%20de%20voc%C3%AAs%20no%20site,%20e%20gostaria%20de%20saber%20o%20que%20%C3%A9%20preciso%20fazer%20para%20entrar%20na%20lista%20de%20espera😁">
        Whatsapp
      </a>
    </div>
  )

}

export default Contact
