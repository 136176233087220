import _ from "./index.scss"

import React, {useEffect, useState, useContext} from 'react'

import { TimerzoneContext } from '../Timerzone/provider'
import { ScrollContext } from '../../providers/Scroll'
import { SectionContext } from '../Section/provider'

const Timer = ({children, offset, initial, ...props}) => {

  const [visible, setVisible] = useState(false)

  const timerzone = useContext(TimerzoneContext)
  
  const scroll = useContext(ScrollContext)
  const {y} = scroll

  const section = useContext(SectionContext)

  const scaleValue = (value, offset) => {
    return (value - offset[0]) / (offset[1] - offset[0])
  }

  const changeVisible = (active, progress) => {
    var progress = scaleValue(section.progress, offset)
    var active = section.progress > offset[0] && section.progress < offset[1]

    if (active) {
      var index = timerzone.offsets.findIndex(([value]) => value == offset[0])
      timerzone.onChange(index, {progress})
    } else {
      if (section.progress == 1) timerzone.onChange(null, {progress: 1})
      if (section.progress == 0) timerzone.onChange(null, {progress: 0})
    }

    if (initial && progress <= 0) active = true
    if (initial && progress > 0) active = false

    setVisible(active)
  }

  useEffect(() => {
    changeVisible()
  }, [y])

  return (
    <div 
      {...props}
      className="timer"
      style={{
        opacity: visible ? 1 : 0,
        position: visible ? 'relative' : 'absolute'
      }}
    >
      <TimerContent visible={visible}>
        {children} 
      </TimerContent>
    </div>
  )
}

var TimerContent = ({children}) => children

export default Timer