import _ from './index.scss'

import React, { useState, useRef, useEffect, useContext } from 'react'

import { useInView } from 'react-intersection-observer'

import Section from 'components/Section'
import Effect from 'components/Effect'
import Video from 'components/Video'
import Image from 'components/Image'
import Space from 'components/Space'
import Title from 'components/Title'

import Text from 'ui-kit/components/Text'
import Wrapper from 'ui-kit/components/Wrapper'

import Image1 from 'images/image1.jpg'
import Video1 from 'images/videos/video1.mp4'

import { ScrollContext } from '../../../../providers/Scroll'

const Component = () => {

  const scroll = useContext(ScrollContext)

  // Photo Section
  const [photoTextRef, photoTextInView] = useInView()

  const [media, setMedia] = useState('photo')

  // Media (Photo / Video)
  const mediaStickyRef = useRef(null)
  const [mediaShadowOn, setMediaShadowOn] = useState(null)

  // Texts
  const text1Ref = useRef(null)
  const text2Ref = useRef(null)
  const text3Ref = useRef(null)
  const textRefs = [text1Ref, text2Ref, text3Ref]
  const [textVisibleIndex, changeTextVisibleIndex] = useState(0)

  const [visible, setVisibility] = useState(false)

  useEffect(() => {
    handleTextSwipes()
    handleMediaShadow()
  }, [scroll.y])

  useEffect(() => {
    if (photoTextInView) {
      setMedia("photo")
    } else {
      if (scroll.direction == 1) {
        setMedia("video")
      } else {
        setMedia("photo")
      }
    }
  }, [photoTextInView])

  const handleTextSwipes = () => {
    textRefs.forEach((textRef, index) => {
      const distanceFromTopReached = textRef.current.getBoundingClientRect().top <= window.innerHeight / 1.5
      
      if (distanceFromTopReached) {
        changeTextVisibleIndex(index)
      }
    })
  }

  const handleMediaShadow = () => {
    const stickedToTop = mediaStickyRef.current.getBoundingClientRect().top <= 0
    setMediaShadowOn(stickedToTop)
  }

  const textsParallaxOffset = 70
  const fillParent = { width: "100%", height: "100%" }

  const renderText = (text, index) => {
    const last = textRefs.length == index + 1
    var style = {}

    if (last) {
      style = { margin: "0vh 0 90vh" }
    } else {
      style = { margin: "0vh 0 40vh" }
    }

    return (
      <div ref={textRefs[index]} style={style}>
        <Effect notControlled duration={.8} name="opacity" value={textVisibleIndex == index ? 1 : 0}>
          <Effect 
            notControlled 
            duration={.8} 
            name="translate" 
            value={textVisibleIndex == index ? .5 : textVisibleIndex > index ? 0 : 1} 
            from={-textsParallaxOffset} 
            to={textsParallaxOffset}
          >
            <Title size="base" align="left">{text}</Title>
          </Effect>
        </Effect>
      </div>
    )
  }

  return (
    <Section 
      hideHeader
      onChangeVisibility={setVisibility}
    >
      <div style={{position: "relative"}}>
        {/* Medias */}
        <div ref={mediaStickyRef} style={{width: "100%", height: "100vh", position: "sticky", top: 0}}>
          <div style={{position: "relative", ...fillParent}}>
            <div style={{position: "absolute", top: 0, ...fillParent}}>
              <Effect notControlled name="opacity" value={media == "photo" ? 1 : 0} style={fillParent}>
                <Image src={Image1} fit="cover" />
              </Effect>
            </div>

            <div style={{position: "absolute", top: 0, ...fillParent}}>
              <Effect notControlled name="opacity" value={media == "video" ? 1 : 0} style={fillParent}>
                <Video src={Video1} loop start={media == "video" && visible} opacity={.8} />
              </Effect>
            </div>
          </div>

          <Effect notControlled name="opacity" value={mediaShadowOn ? 1 : 0}>
            <div className="machine-medias-shade" />
          </Effect>
        </div>
        
        <Wrapper>

          {/* Section 1 Text */}
          <div ref={photoTextRef} style={{position: "relative", marginTop: "-50vh"}}>
            <Effect notControlled name="opacity" value={mediaShadowOn ? 1 : 0}>
              <Title size="base" align="left">
                Que tal ter uma academia <br/> <Text color={Text.constants.color.orange50}>diferente?</Text>
              </Title>
            </Effect>
          </div>

          {/* Blank separator for keep correctness of inView photoTextRef */}
          <Space height={100} />

          {/* Section 2 Texts */}
          {renderText(
            <>
              Que tal ter uma academia <br/> <Text color={Text.constants.color.orange50}>+ moderna?</Text>
            </>, 0
          )}

          {renderText(
            <>
              Que tal ter uma academia <br/> <Text color={Text.constants.color.orange50}>+ evoluída?</Text>
            </>, 1
          )}

          {renderText(
            <>
              Que tal ter uma academia <br/> <Text color={Text.constants.color.orange50}>+ impressionante?!</Text>
            </>, 2
          )}
        </Wrapper>
      </div>
    </Section>
  )

}

export default Component