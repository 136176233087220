import React, { useState } from 'react'

import Section from 'components/Section'
import Effect from 'components/Effect'
import Timerzone from 'components/Timerzone'
import Timer from 'components/Timer'
import Zone from 'components/Zone'
import Title from 'components/Title'

import Wrapper from 'ui-kit/components/Wrapper'
import Flex from 'ui-kit/components/Flex'

import Legpress1 from 'images/legpress1.jpg'
import Legpress2 from 'images/legpress2.jpg'
import Legpress3 from 'images/legpress3.jpg'

const Component = () => {

  const [progress, setProgress] = useState(0)

  const renderText = (text) => {
    return (
      <Flex alignItems="center" justifyContent="center" style={{width: "100%", height: "100%"}}>
        <Wrapper>
          <Title size="base">
            {text}
          </Title>
        </Wrapper>
      </Flex>
    )
  }

  return (
    <>

      <Section style={{minHeight: "20vh", margin: "50vh 0 20vh 0"}}>
        {renderText("Sabia que até 1980 academias não tinham bebedouro?")}
      </Section>

      <Section style={{minHeight: "300vh", display: "block"}}>
        <Timerzone 
          onChange={(index, {progress}) => {
            setProgress(progress)
          }} 
          offsets={[[0, .8], [.8, 1]]}
        >
          <Timer initial offset={[0, .8]}>
            {renderText(
              <>
                <div>&nbsp;</div>
                <br/>
                <img src={Legpress1} width={200} height={200} />
              </>
            )}
          </Timer>

          <Timer offset={[0, .8]}>
            {renderText(
              <>
                <Zone value={progress} offset={[0, .8]}>
                  {({ value, started }) => (
                    <Effect name="translate" value={value} from={30} to={0}>
                      <Effect name="opacity" value={value}>
                        <Title size="base">Nem legpress</Title>
                      </Effect>
                    </Effect>
                  )}
                </Zone>

                <br/>

                {progress <= .3 && (
                  <img src={Legpress1} width={200} height={200} />
                )}

                {(progress > .3 && progress <= .6) && (
                  <img src={Legpress2} width={200} height={200} />
                )}

                {progress > .6 && (
                  <img src={Legpress3} width={200} height={200} />
                )}
              </>
            )}
          </Timer>

          <Timer offset={[.8, 1]}>
            {renderText(
              <>
                <div>Nem legpress</div>
                <br/>
                <img src={Legpress3} width={200} height={200} />
              </>
            )}
          </Timer>
        </Timerzone>
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "0vh 0 20vh 0"}}>
        {renderText("A experiência com a 1Will é mesma coisa")}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("Quem experimenta fica mimado")}
      </Section>
      
      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("Nunca mais quer voltar para o jeito antigo")}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("Faça como 34 das melhores academias de SP")}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText(
          <>
            Diferencie sua academia
            <Title type="secondary" size="xsmall">
              <br/>
              Seu cliente vai <br/> se sentir no futuro
            </Title>
          </>
        )}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("E ganhe dinheiro com isso")}
      </Section>
    </>
  )

}

export default Component