import React from 'react'

import Contact from 'components/Contact'
import ScrollWheel from 'components/ScrollWheel'
import Header from 'components/Header'
import Footer from 'components/Footer'

import {Section0, Section1, Section2, Section3, Section4, Section5, Section6, Section7, Section8, Section9} from './components'

const Init = () => {

  return <>
    <Header />

    <Section0 />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
    <Section7 />
    <Section8 />
    {/* <Section9 /> */}

    <Footer>
      <ScrollWheel />
      <Contact />
    </Footer>
  </>

}

export default Init
