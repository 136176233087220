import React, {useEffect, useState, createContext} from 'react'

const CacheContext = createContext()

const importAll = (r) => r.keys().map(r)

const common = importAll(require.context('../../images', false, /\.(jpg|png)$/)).map((i) => i.default)
const frames = importAll(require.context('../../images/frames', false, /\.(jpg)$/)).map((i) => i.default)

const CacheProvider = (props) => {

  const [ready, setReady] = useState(false)

  const imagesRequiredLoader = [
    ...common
  ]

  const imagesLoader = [
    ...frames
  ]

  const preloadImage = (src) => {
    return new Promise(r => {
      const image = new Image()
      
      image.onload = r
      image.onerror = r
      image.src = src
    })
  }

  useEffect(() => {
    if (ready) {
      setTimeout(() => {
        imagesLoader.map(preloadImage)
      }, 5000)
    }
  }, [ready])

  useEffect(() => {
    Promise
      .all(imagesRequiredLoader.map(preloadImage))
      .then(() => setReady(true))
  })

  if (!ready) return null

  return (
    <CacheContext.Provider 
      value={{
        images: {
          common,
          frames,
        }
      }}
    >
      {props.children}
    </CacheContext.Provider>
  )
}

export {
  CacheProvider,
  CacheContext
}
