import React, { useState, useRef, useEffect, useContext } from 'react'

import { useInView } from 'react-intersection-observer'

import Section from 'components/Section'
import Effect from 'components/Effect'
import Video from 'components/Video'
import Space from 'components/Space'
import Title from 'components/Title'
import Icon from 'ui-kit/components/Icon'

import Wrapper from 'ui-kit/components/Wrapper'

import Video1 from 'images/videos/video3.mp4'
import Video2 from 'images/videos/video4.mp4'

import { ScrollContext } from '../../../../providers/Scroll'

const Component = () => {

  const scroll = useContext(ScrollContext)

  const [listRef, listInView] = useInView()

  const [media, setMedia] = useState('video1')

  // Texts
  const text1Ref = useRef(null)
  const text2Ref = useRef(null)
  const text3Ref = useRef(null)
  const text4Ref = useRef(null)
  const text5Ref = useRef(null)
  const text6Ref = useRef(null)
  const text7Ref = useRef(null)
  const text8Ref = useRef(null)
  const text9Ref = useRef(null)
  const textRefs = [text1Ref, text2Ref, text3Ref, text4Ref, text5Ref, text6Ref, text7Ref, text8Ref, text9Ref]

  const [textVisibleIndex, changeTextVisibleIndex] = useState(0)

  const [visible, setVisibility] = useState(false)

  useEffect(() => handleTextSwipes, [scroll.y])

  useEffect(() => {
    if (listInView) {
      setMedia("video1")
    } else {
      if (scroll.direction == 1) {
        setMedia("video2")
      } else {
        setMedia("video1")
      }
    }
  }, [listInView])

  const handleTextSwipes = () => {
    textRefs.forEach((textRef, index) => {
      const distanceFromTopReached = textRef.current.getBoundingClientRect().top <= window.innerHeight * 0.2

      if (distanceFromTopReached) {
        if (index + 1 < textRefs.length) changeTextVisibleIndex(index + 1)
      }
    })
  }

  const textsParallaxOffset = 70
  const fillParent = { width: "100%", height: "100%" }

  const renderText = (text, index, align="left") => {
    const last = textRefs.length == index + 1
    var style = {}

    if (last) {
      style = { margin: "0vh 0 90vh" }
    } else {
      style = { margin: "0vh 0 35vh" }
    }

    return (
      <div ref={textRefs[index]} style={style}>
        <Effect notControlled duration={.8} name="opacity" value={textVisibleIndex == index ? 1 : 0}>
          <Effect 
            notControlled 
            duration={.8} 
            name="translate" 
            value={textVisibleIndex == index ? .5 : textVisibleIndex > index ? 0 : 1} 
            from={-textsParallaxOffset} 
            to={textsParallaxOffset}
          >
            <Title size="large" align={align}>{text}</Title>
          </Effect>
        </Effect>
      </div>
    )
  }

  return (
    <Section 
      hideHeader
      onChangeVisibility={setVisibility}
    >
      <div style={{position: "relative"}}>

        {/* Medias */}
        <div style={{width: "100%", height: "100vh", position: "sticky", top: 0}}>
          <div style={{position: "relative", ...fillParent}}>
            <div style={{position: "absolute", top: 0, ...fillParent}}>
              <Effect notControlled name="opacity" value={media == "video1" ? 1 : 0} style={fillParent}>
                <Video src={Video1} loop start={media == "video1" && visible} />
              </Effect>
            </div>

            <div style={{position: "absolute", top: 0, ...fillParent}}>
              <Effect notControlled name="opacity" value={media == "video2" ? 1 : 0} style={fillParent}>
                <Video src={Video2} loop start={media == "video2" && visible} />
              </Effect>
            </div>
          </div>
        </div>

        {/* Section 2 Texts */}
        <Wrapper>
          <div className="list" ref={listRef}>
            {renderText("Mistura insanamente perfeita", 0)}
            {renderText("Impossível de replicar em casa", 1)}
            {renderText("Cremosidade absurda", 2)}
            {renderText("11 Tipos de suplementos", 3)}
            {renderText(<><Icon name="zap" /> <br/> Pré Treino</>, 4, "center")}
            {renderText(<><Icon name="biceps" /> <br/> Whey</>, 5, "center")}
            {renderText(<><Icon name="no-milk" /> <br/> Zero Lactose</>, 6, "center")}
            {renderText("Entre outros", 7, "center")}
            {renderText(
              <>
                Além de
                <br/><br/>
                <img src="https://media1.giphy.com/media/Wtg8Bmgul1Qxc0otod/giphy.gif" width={50} />
              </>, 8, "center"
            )}
          </div>

          <Space height={60} />
        </Wrapper>
      </div>
    </Section>
  )

}

export default Component