import _ from "./index.scss"

import React, { useEffect, useRef, useState, useContext } from 'react'

import { ScrollContext } from '../../providers/Scroll'

import Icon from "ui-kit/components/Icon"

const Video = ({src, start, unmuteControl, fit, speed, ...other}) => {

  const feedbackDuration = .8
  const scroll = useContext(ScrollContext)

  const elRef = useRef(null)
  const interval = useRef()

  const [muted, toggleMute] = useState(true)
  const [resettingFeedback, setResettingFeedback] = useState(false)

  const handleMute = () => {
    if (unmuteControl) {
      elRef.current.muted = !elRef.current.muted
      toggleMute(elRef.current.muted)
    }
  }

  const resetVideo = () => {
    if (interval.current) clearTimeout(interval.current)
    
    elRef.current.currentTime = 0
  }

  const handleResettingFeedback = () => {
    setResettingFeedback(true)
    
    interval.current = setTimeout(() => resetVideo, feedbackDuration * 1000)
  }

  useEffect(() => {
    if (start) {
      if (scroll.direction == 1) {
        resetVideo()
      }
      
      setResettingFeedback(false)

      elRef.current.playbackRate = speed
      elRef.current.play()
    } else {
      if (scroll.direction == -1) {
        handleResettingFeedback()
      }

      elRef.current.pause()
    }
  }, [start])

  return (
    <div 
      className={`video ${resettingFeedback && 'video--resetting'}`} 
      style={{
        '--fit': fit,
        '--delay': `${feedbackDuration}s`
      }}
    >
      <video 
        ref={elRef}
        preload="auto" 
        muted 
        playsInline
        autoPlay={false}
        onClick={handleMute}
        {...other}
      >
        <source src={src} type="video/mp4" />
      </video>

      {unmuteControl && (
        <div className={`video__unmute ${!muted && 'video__unmute--active'}`} onClick={handleMute}>
          <a className="video__unmute__inner">
            <Icon name={muted ? "volume-x" : "volume"} />
          </a>
        </div>
      )}
    </div>
  )
  
}

Video.defaultProps = {
  loop: true,
  speed: 1,
  fit: "cover"
}

export default React.memo(
  Video, 
  (prevProps, nextProps) => prevProps.start === nextProps.start
)