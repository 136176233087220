import _ from './index.scss'

import React from 'react'

import Section from 'components/Section'
import Image from 'components/Image'
import Fade from 'components/Fade'
import Title from 'components/Title'

import Wrapper from 'ui-kit/components/Wrapper'

import Logo from 'images/logo_text.svg'
import MachineShadow from 'images/machine-shadow.png'
import MachineForeground from 'images/machine-foreground.png'

const Component = () => {

  return (
    <>
      <Section 
        style={{height: "120vh"}}
      >
        <div className="splash_content">
          <div className="splash_content__inner">
            <Wrapper>
              <div className="splash_logo">
                <Fade delay=".2s">
                  <img src={Logo} />
                </Fade>
                <Fade delay=".5s">
                  <div className="splash__slogan">
                    <Title size="small" align="center" type="secondary">
                      Máquina de Suplementos
                    </Title>
                  </div>
                </Fade>
              </div>
            </Wrapper>
            <div className="splash_image">
              <Fade delay=".6s">
                <div className="splash_image__foreground">
                  <Image src={MachineForeground} />
                </div>
              </Fade>
              <Fade delay=".8s" fade=".8">
                <div className="splash_image__background">
                  <Image src={MachineShadow} />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </Section>

      <Section 
        style={{margin: "0 0 20vh 0"}}
      >
        <Wrapper>
          <Title size="large" align="center">Não deixe sua academia <br/> como em 1999</Title>
        </Wrapper>
      </Section>
    </>
  )

}

export default Component