import React from 'react'

const Svg = ({children}) => {

  return (
    <div className="uiKit-svg">
      {children}
    </div>
  )

}

export default Svg