import _ from "./index.scss"

import React from 'react'

const Title = ({children, align, size, label, type, style}) => {

  if (size == "large") size = "35px"
  if (size == "base") size = "30px"
  if (size == "small") size = "23px"
  if (size == "xsmall") size = "20px"
  
  return (
    <div 
      className={`title title--${type} title--label-${label}`}
      style={{
        ...style,
        '--size': size,
        '--align': align
      }}
    >
      {children}
    </div>
  )

}

Title.defaultProps = {
  size: "base",
  align: "center",
  type: "primary",
  label: false
}

export default Title