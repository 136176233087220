import _ from "./index.scss"

import React from 'react'

const Effect = ({children, name, notControlled, duration, value, from, to, style}) => {

  const scaleValue = (value, from, to) => {
    var scale = (to[1] - to[0]) / (from[1] - from[0])
    var capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
    
    return capped * scale + to[0]
  }

  if (notControlled) {
    // value = value > 0 ? 1 : 0

    if (name == "rotate")    style = {...style, "--value": `${value * -90}deg`}
    if (name == "translate") style = {...style, "--value": `${scaleValue(value, [0, 1], [from, to])}px`}
    if (name == "opacity")   style = {...style, "--value": value}

    style = {...style, "--duration": `${duration}s`}

    return (
      <div 
        className={`effect effect--${name}`}
        style={style}
      >
        {children}
      </div>
    )
  } {
    if (name == "rotate")    style = {...style, "--value": `${value * -90}deg`}
    if (name == "translate") style = {...style, "--value": `${scaleValue(value, [0, 1], [from, to])}px`}
    if (name == "opacity")   style = {...style, "--value": value}

    return (
      <div 
        className={`effect effect--${name}`}
        style={style}
      >
        {children}
      </div>
    )
  }

}

Effect.defaultProps = {
  duration: .3
}

export default Effect