import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledComponent = styled.div`
  display: inline;
  font-size: var(--fontSize${props => props.fontSize});
  font-weight: var(--fontWeight${props => props.fontWeight});
  color: var(--color${props => props.color});
  font-family: var(--fontFamily${props => props.fontFamily});
  font-style: ${props => props.fontStyle};
`

var Text = ({children, ...other}) => {
  return (
    <StyledComponent {...other} className="uiKit-text">
      {children}
    </StyledComponent>
  )
}

Text.constants = {
  fontFamily: {
    base: "Base",
    heading: "Heading"
  },
  fontSize: {
    xxlarge: "XXLarge",
    xlarge: "XLarge",
    base: "Base",
    large: "Large",
    small: "Small",
    xsmall: "XSmall",
    xxsmall: "XXSmall"
  },
  fontWeight: {
    regular: 'Regular',
    semiBold: 'SemiBold',
    bold: 'Bold',
    bolder: 'Bolder',
    black: 'Black',
  },
  color: {
    dark100: "Dark100",
    dark90: "Dark90",
    dark75: "Dark75",
    dark50: "Dark50",
    dark25: "Dark25",
    dark15: "Dark15",
    dark5: "Dark5",
    dark0: "Dark0",
    orange50: "Orange50",
  }
}

Text.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  fontStyle: PropTypes.string,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
}

Text.defaultProps = {
  fontSize: "inherit",
  fontWeight: "inherit",
  color: "inherit",
  fontFamily: "inherit"
}

export default Text