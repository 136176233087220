import React from 'react'
import PropTypes from 'prop-types'

import Svg from 'ui-kit/components/Svg'

import {ReactComponent as Volume} from 'images/icons/volume.svg'
import {ReactComponent as VolumeX} from 'images/icons/volume-x.svg'
import {ReactComponent as Send} from 'images/icons/send.svg'
import {ReactComponent as Zap} from 'images/icons/zap.svg'
import {ReactComponent as Biceps} from 'images/icons/biceps.svg'
import {ReactComponent as NoMilk} from 'images/icons/no-milk.svg'

const ICONS = {
  Volume, VolumeX, Send, Zap, Biceps, NoMilk
}

const Icon = ({name, ...other}) => {

  const snakeToCamel = () => {
    return capitalize().replace(/(\-\w)/g, (m) => m[1].toUpperCase())
  }

  const capitalize = () => {
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  var className

  if (!name) return null

  const Icon = ICONS[snakeToCamel()]

  if (ICONS[snakeToCamel()]) {
    return (
      <div
        {...other}
        className={`uiKit-icon ${className}`}
      >
        <Svg>
          <Icon />
        </Svg>
      </div>
    )
  } else {
    throw new Error(`Icon '${name}' was not found`)
  }

}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}

export default Icon
