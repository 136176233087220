import './ui-kit/index.scss'
import './containers/index.scss'

import React, {Component} from 'react'
import ReactDOM from 'react-dom'

import App from './containers/App'

import { ScrollProvider } from './providers/Scroll'
import { CacheProvider } from './providers/Cache'
import { HeaderProvider } from './providers/Header'

ReactDOM.render(
  <CacheProvider>
    <ScrollProvider>
      <HeaderProvider>
        <App />
      </HeaderProvider>
    </ScrollProvider>
  </CacheProvider>, 
  document.getElementById("app")
)
