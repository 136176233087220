import React from 'react'

import Section from 'components/Section'

import Wrapper from 'ui-kit/components/Wrapper'
import Text from 'ui-kit/components/Text'

import Title from 'components/Title'

const Component = () => {

  const renderText = (text) => {
    return (
      <Wrapper>
        <Title size="base">
          {text}
        </Title>
      </Wrapper>
    )
  }

  return (
    <>
      <Section scale style={{minHeight: "20vh", margin: "50vh 0 20vh 0"}}>
        {renderText(<>E ainda <br/> <Text color={Text.constants.color.orange50}>ganhar dinheiro</Text> <br/> com isso!</>)}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText("De uma forma única e diferenciada")}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText((
          <>
            Fidelizando seus alunos em <br/> 
            <Text color={Text.constants.color.orange50}>planos mais longos</Text>
          </>
        ))}
      </Section>

      <Section scale style={{minHeight: "30vh", margin: "20vh 0"}}>
        {renderText("Como?")}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText(
          <>
            <Title type="secondary" label size="xsmall">Por exemplo</Title>
            Se sua mensalidade <br/> for R$ 200
          </>
        )}
      </Section>

      <Section scale style={{minHeight: "20vh", margin: "20vh 0"}}>
        {renderText(
          <>
            Você poderá fazer assim
            <br/><br/>
            <img src="https://media1.giphy.com/media/Wtg8Bmgul1Qxc0otod/giphy.gif" width={50} />
          </>
        )}
      </Section>
    </>
  )

}

export default Component