import React, {useState, createContext} from 'react'

const HeaderContext = createContext()

const HeaderProvider = ({children, ...props}) => {

  const [blocked, setBlocked] = useState(false)

  return (
    <HeaderContext.Provider 
      value={{
        blocked,
        setBlocked
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export {
  HeaderProvider,
  HeaderContext
}