import _ from "./index.scss"

import React from 'react'

const Sticky = ({children}) => {

  return (
    <div className="sticky">
      {children}
    </div>
  )

}

Sticky.Inner = ({children}) => {
  return (
    <div className="sticky__inner">
      {children}
    </div>
  )
}

export default Sticky