import _ from "./index.scss"

import React, {useState, useContext, useEffect} from 'react'

import { ScrollContext } from '../../providers/Scroll'

const ScrollWheel = ({}) => {

  const scroll = useContext(ScrollContext)

  const [visible, setVisibility] = useState(true)

  useEffect(() => {
    setVisibility(scroll.y == 0)
  }, [scroll.y])
  
  return (
    <div className="wheel-indicator-wrapper">
      {visible 
        ? (
          <div className="wheel-indicator">
            <div className="wheel-indicator__ball" />
          </div>
        )
        : null
      }
    </div>
  )

}

export default ScrollWheel