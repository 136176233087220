import React, {createContext} from 'react'

const TimerzoneContext = createContext()

const TimerzoneProvider = ({children, offsets, onChange}) => {

  return (
    <TimerzoneContext.Provider 
      value={{
        onChange,
        offsets
      }}
    >
      {children}
    </TimerzoneContext.Provider>
  )
}

export {
  TimerzoneProvider,
  TimerzoneContext
}