import _ from "./index.scss"

import React from 'react'

import Sticky from 'components/Sticky'

import { TimerzoneProvider } from './provider'

const Timerzone = ({children, ...props}) => {

  return (
    <Sticky>
      <TimerzoneProvider {...props}>
        <div className="timerzone">
          <Sticky.Inner>
            {children}
          </Sticky.Inner>
        </div>
      </TimerzoneProvider>
    </Sticky>
  )
}

Timerzone.defaultProps = {
  onChange: () => {}
}

export default Timerzone