import React, {useEffect} from 'react'

import Init from '../Init'

const App = () => {

  useEffect(() => {
    history.scrollRestoration = "manual"

    window.addEventListener("beforeunload", () => {
      window.scroll(0, 0)
    })
  }, [])

  return <Init />
}

export default App
